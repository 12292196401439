import { Ref } from '@vue/reactivity';
import request from '@/tools/request';

interface CodeType {
  status: number;
  msg: string;
  data: string;
}

export const getCode = (loading: Ref<boolean>) => {
  return request({
    url: '/detailinquiry-index-set.html',
    loading,
  }) as Promise<CodeType>;
};
