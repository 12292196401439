<template>
  <div class="home">
    <header>
      <m-page-header title="操作指南"></m-page-header>
    </header>
    <main>
      <div class="code-wrap">
        <FormSkeleton :loading="loading" type="text">
          <textarea class="code" :value="code" disabled></textarea>
        </FormSkeleton>

        <div class="copy">
          <el-button
            type="primary"
            size="medium"
            @click="copyCode"
            icon="el-icon-document-copy"
            :disabled="loading"
          >
            复制
          </el-button>
        </div>
      </div>
      <div class="description">
        <ul>
          <li class="title">
            <h5>操作指南</h5>
          </li>
          <li>1.将代码复制至分类自定义描述插件</li>
          <li>2.详细页询盘信息在商城询盘列表查看</li>
        </ul>
      </div>
      <div class="images">
        <m-image-preview :imgList="[homePreview1, homePreview2]" />
      </div>
    </main>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import MPageHeader from '@/components/pageHeader/PageHeader.vue';
import MImagePreview from '@/components/imagePreview/ImagePreview.vue';

import homePreview1 from '../statics/img/home1.jpg';
import homePreview2 from '../statics/img/home2.jpg';
import FormSkeleton from '@/components/formSkeleton/FormSkeleton.vue';
import { getCode } from '../api/home';
import { copy } from '@/tools';
import { ElMessage } from 'element-plus';

export default {
  name: 'Home',
  components: {
    'm-page-header': MPageHeader,
    'm-image-preview': MImagePreview,
    FormSkeleton,
  },
  setup() {
    const code = ref('');
    const loading = ref(false);

    const initCode = async () => {
      const res = await getCode(loading);

      if (res.status === 1) {
        code.value = res.data;
      }
    };

    const copyCode = () => {
      if (copy(code.value)) {
        ElMessage.success({
          message: '复制成功',
        });
      } else {
        ElMessage.error({
          message: '复制失败，可能您的浏览器或系统版本过低',
        });
      }
    };

    onMounted(() => {
      initCode();
    });

    return {
      homePreview1,
      homePreview2,
      loading,
      code,
      copyCode,
    };
  },
};
</script>

<style lang="scss" scoped>
main {
  .code-wrap {
    margin: 20px;
    margin-bottom: 0;
    .code {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      line-height: 2;
      background-color: #fff;
      width: 900px;
      height: 172px;

      &:focus {
        background-color: #000;
      }
    }
    .copy {
      margin-top: 16px;
    }
  }
  .description {
    ul {
      padding: 20px;
      li {
        padding: 8px 0;
        .title {
          padding: 10px 0;
        }
      }
    }
  }
  .images {
    padding: 20px;
  }
}
</style>
